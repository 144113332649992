import React, { useState } from "react";
import Title from "../common/Title/Title";
import { useSelector } from "react-redux";
import axios from "axios";
import { base_url } from "../../api";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { getErrorMessages } from "../../util/helper";

import "./Checkout.css";

const Checkout = () => {
  const [errorMsg, setErrorMsg] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [paymentFail, setPaymentFail] = useState(false);
  const [paymentErrMsg, setPaymentErrMsg] = useState("");
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });

  const data = useSelector((state) => state.cart.cartList);
  const stripe = useStripe();
  const elements = useElements();

  function calculateTotalPrice(products) {
    return products.reduce((acc, product) => {
      return acc + product.price * product.qty;
    }, 0);
  }

  const handleInputChange = (e) => {
    resetPaymentError();

    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //reset payment error message
  const resetPaymentError = () => {
    setPaymentFail(false);
    setPaymentErrMsg("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    resetPaymentError();

    // return;
    if (!stripe || !elements) return;

    try {
      setIsLoading(true);
      axios
        .post(`${base_url}/stripecheckout`, {
          items: data,
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          phone: userData.phone,
        })
        .then((response) => {
          const { clientSecret } = response.data;
          const { payment_id } = response.data;

          stripe
            .confirmCardPayment(clientSecret, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  name: userData.first_name + " " + userData.last_name,
                },
              },
            })
            .then((result) => {
              if (result.error) {
                setPaymentFail(true);
                setPaymentErrMsg(result.error.message);
              } else {
                if (result.paymentIntent.status === "succeeded") {
                  // add api call to confirm payment
                  axios
                    .post(`${base_url}/stripeconfirm`, {
                      payment_id: payment_id,
                    })
                    .then((confirmresponse) => {
                      console.log("Payment successful", confirmresponse);
                      localStorage.clear();
                      window.location.href = `${process.env.REACT_APP_WEBSITE_URL}checkout/success`;
                    })
                    .catch((errorresponse) => {
                      console.error("Error confirming payment:", errorresponse);
                      setPaymentFail(true);
                      setPaymentErrMsg(errorresponse.message);
                    })
                    .finally(() => setIsLoading(false));
                }
              }
            })
            .catch((error) => {
              console.error("Error confirming payment:", error);
              setPaymentFail(true);
              setPaymentErrMsg(error.message);
            })
            .finally(() => setIsLoading(false));
        })
        .catch((error) => {
          setPaymentFail(true);

          if (error.response?.data?.message) {
            const errMsg = getErrorMessages(error.response?.data?.message);
            setPaymentErrMsg(errMsg);
          } else {
            setPaymentErrMsg("Network Error!! Something went wrong.");
          }
        });
    } catch (error) {
      console.log(error);
      setErrorMsg(error.response.message);
    }
  };

  return (
    <section className="checkout__sec">
      <Title title={"Checkout"} />

      <div className="container_checkout">
        <article className="information">
          <h1> YOUR INFORMATION</h1>
          <div className="title__bottom-line" />

          <form onSubmit={handleSubmit}>
            <div className="information_input">
              <div>
                <label className="label">
                  <span>*</span> First Name{" "}
                  <span>
                    {" "}
                    {errorMsg?.first_name && "This field is required"}
                  </span>
                </label>
                <br />
                <input
                  type="text"
                  name="first_name"
                  required
                  value={userData.first_name}
                  onChange={handleInputChange}
                  placeholder="First Name"
                />
              </div>
              <div>
                <label className="label">
                  <span>*</span> Last Name{" "}
                  <span>{errorMsg?.last_name && "This field is required"}</span>
                </label>
                <br />
                <input
                  type="text"
                  name="last_name"
                  required
                  value={userData.last_name}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                />
              </div>
              <div>
                <label className="label">
                  <span>*</span> Email{" "}
                  <span> {errorMsg?.email && "This field is required"}</span>
                </label>
                <br />
                <input
                  type="email"
                  name="email"
                  required
                  value={userData.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </div>
              <div>
                <label className="label">
                  <span>*</span> Phone{" "}
                  <span> {errorMsg?.phone && "This field is required"}</span>
                </label>
                <br />
                <input
                  type="number"
                  name="phone"
                  required
                  value={userData.phone}
                  onChange={handleInputChange}
                  placeholder="Phone"
                />
              </div>
            </div>
            <div className="pin-card-field">
              <label className="label" style={{ marginBottom: "3px" }}>
                <span>*</span> Card Details{" "}
                <span> {errorMsg?.card && "This field is required"}</span>
              </label>
              <br />
              {/* <StripeContainer> */}
              {/* <CardElement /> */}
              {/* </StripeContainer> */}
              <CardElement
                onChange={resetPaymentError}
                options={{
                  hidePostalCode: true,
                  style: {
                    base: {
                      fontSize: "16px",
                      color: "#ffffff",
                      "::placeholder": {
                        color: "#aab7c4",
                      },
                      borderBottom: "2px solid #ffffff",
                    },
                    invalid: {
                      color: "#fa755a",
                    },
                  },
                }}
              />
            </div>
            <button type="submit" disabled={isLoading} className="pay-btn">
              {isLoading ? <div class="loader"></div> : "Pay Now"}
            </button>
            <p
              style={{
                textAlign: "center",
                color: "red",
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              {paymentFail && paymentErrMsg}
            </p>
          </form>
        </article>
        <div className="subcontainer">
          <article>
            <h1>YOUR ORDER</h1>
            <div className="title__bottom-line" />
            <div className="order_status">
              <table>
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>QTY</th>
                    <th>PRICE</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item) => (
                      <tr key={item.id}>
                        <td>{item.type}</td>
                        <td>{item.qty}</td>
                        <td>$ {item.qty * item.price}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="total">
              <h2>TOTAL</h2>
              <h2>HK $ {calculateTotalPrice(data)}</h2>
            </div>
          </article>
          {data &&
            data.map((item) => (
              <article key={item.id} className="booking_container">
                <div className="package_img-container">
                  <img
                    alt="title"
                    src={item.img_path}
                    className="package_img"
                  />
                </div>
                <div className="info-content">
                  <h1 className="pt-4">{item.type}</h1>
                  <div className="title__bottom-line" />
                  <div
                    className="text-center rendeHtml"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  ></div>
                  <h1 className="pt-2">HK $ {item.price}</h1>
                </div>
              </article>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Checkout;
