import React from "react";
import Footer from "../Footer/Footer";
import galleryVideo1 from "../../util/galleryVideo1.mp4";
import galleryVideo2 from "../../util/galleryVideo2.mp4";
import galleryPhoto1 from "../../util/galleryPhoto1.png";
import galleryPhoto2 from "../../util/galleryPhoto2.png";
import "./Gallery.css";

const Gallery = ({ galleries }) => {
  const gallery = galleries.galleryCategories.filter(
    (x) => x.name == "highlights"
  );

  return (
    <div className="Gallery-page">
      <div className="gallery">
        <div className="gallery-big">
          <h2 className="gallery-title">
            OUR GALLERY<div className="mobile-title-line"></div>
          </h2>
          <h5 className="gallery-description">
            RECORD YOUR MEMORIES IN GRAFFITI
          </h5>
        </div>
        <div className="gallery-top">
          <h2 className="gallery-mobile-title1">GRAFFITI PARTY 2021</h2>
          <p className="gallery-mobile-description1">
            The Party District No1!
            <br />
            Many Bars And Clubs One Less Than 2k㎡. You Can Do Club/Bar Hopping
            And Go Where Ever You Want. The Music Is Almost The Same (Western
            Party Music). I Can Recommend China Bar And Graffiti! Both Clubs
            Play Really Good, Loud Music And They Are The Best Places To Dance.
          </p>
          <video className="video-gallery" autoPlay playsInline muted loop>
            <source src={galleryVideo1} type="video/mp4" />
          </video>
          {/* <img src={galleryVideo1} alt="" className="galleryVideo1" /> */}
          <div className="gallery-top-right">
            <h2 className="gallery-top-right-title">GRAFFITI PARTY 2021</h2>
            <p className="gallery-top-right-description">
              The Party District No1!
              <br />
              Many Bars And Clubs One Less Than 2k㎡. You Can Do Club/Bar
              Hopping And Go Where Ever You Want. The Music Is Almost The Same
              (Western Party Music). I Can Recommend China Bar And Graffiti!
              Both Clubs Play Really Good, Loud Music And They Are The Best
              Places To Dance.
            </p>
            <p className="gallery-top-right-date">25 Feb 2021</p>
            <div className="gallery-top-right2">
              <h4 className="gallery-mobile-title2">
                CHRISTMAS PARTY VIDEO 2020
              </h4>
              {/* <img src={galleryVideo2} alt="" className="galleryVideo2" /> */}
              <video className="video-gallery" autoPlay playsInline muted loop>
                <source src={galleryVideo2} type="video/mp4" />
              </video>
              <h4 className="gallery-top-right2-title">
                CHRISTMAS PARTY VIDEO 2020
              </h4>
              <h6 className="gallery-top-right-date">19 FEB 2020</h6>
            </div>
          </div>
        </div>
        <div className="row mt-5 mx-0 w-100">
          {!gallery
            ? ""
            : gallery[0].galleries.map((highlights) => {
                return (
                  <div className="gallery-vd col-sm-6 col-md-4 col-lg-3">
                    {/* <h5 className="gallery-mobile">
                      {highlights.gallery_title}
                    </h5> */}
                    <img
                      className="gallery-video order-2 mb-1"
                      src={`${process.env.REACT_APP_IMAGE_URL}${highlights.image}`}
                      alt=""
                    />
                    <h5 className="gallery-desktop order-1 order-lg-1">
                      {highlights.gallery_title}
                    </h5>
                    <h6 className="gallery-date order-3">
                      {highlights.gallery_content}
                    </h6>
                  </div>
                );
              })}
          {/* <div className="gallery-vd">
            <h5 className="gallery-mobile">GIRL'S NIGHT(SUMMER2021)</h5>
            <img className="gallery-video" src={galleryPhoto2} alt="" />
            <h5 className="gallery-desktop">GIRL'S NIGHT(SUMMER2021)</h5>
            <h6 className="gallery-date">23 FEB 2021</h6>
          </div>
          <div className="gallery-vd">
            <h5 className="gallery-mobile">GIRL'S NIGHT(SUMMER2021)</h5>
            <img className="gallery-video" src={galleryPhoto1} alt="" />
            <h5 className="gallery-desktop">GIRL'S NIGHT(SUMMER2021)</h5>
            <h6 className="gallery-date">23 FEB 2021</h6>
          </div>
          <div className="gallery-vd">
            <h5 className="gallery-mobile">GIRL'S NIGHT(SUMMER2021)</h5>
            <img className="gallery-video" src={galleryPhoto2} alt="" />
            <h5 className="gallery-desktop">GIRL'S NIGHT(SUMMER2021)</h5>
            <h6 className="gallery-date">23 FEB 2021</h6>
          </div> */}
        </div>
        <h4 className="gallery-bottom-title">JUL 2021</h4>
        <div className="gallery-bottom row">
          {galleries != null
            ? galleries.galleries
                .filter((photo) => photo.category.name === "JUL 2021")
                .map((photo) => (
                  <img
                    key={photo.id}
                    alt=""
                    className="gallery-bottom-photo col-sm-6 col-md-4"
                    src={`${process.env.REACT_APP_IMAGE_URL}${photo.image}`}
                  ></img>
                ))
            : null}
        </div>
        <h4 className="gallery-bottom-title">JUN 2021</h4>
        <div className="gallery-bottom row">
          {galleries != null
            ? galleries.galleries
                .filter((photo) => photo.category.name === "JUN 2021")
                .map((photo) => (
                  <img
                    key={photo.id}
                    alt=""
                    className="gallery-bottom-photo col-sm-6 col-md-4"
                    src={`${process.env.REACT_APP_IMAGE_URL}${photo.image}`}
                  ></img>
                ))
            : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
