import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const addToCart = createAction("cart/addToCart");
export const removeFromCart = createAction("cart/removeFromCart");

export const fetchBooking = createAsyncThunk(
  "booking/fetchBooking",
  async (type) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL_BOOKING}/get-booking-type`
    );
    return response;
  }
);
export const fetchPackageDetails = createAsyncThunk(
  "booking/fetchPackageDetails",
  async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVICE_URL_BOOKING}/get-package-detail?id=${id}`
    );

    return response;
  }
);

const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    bookingList: [],
    packageDetails: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // ...

    builder.addCase(fetchBooking.fulfilled, (state, action) => {
      state.bookingList = action.payload.data.bookings;
    });
    builder.addCase(fetchPackageDetails.fulfilled, (state, action) => {
      state.packageDetails = action.payload.data.selected;
    });
  },
});
export default bookingSlice.reducer;
