export const getErrorMessages = (response) => {
  let errorMessages = "";

  for (let key in response) {
    if (response.hasOwnProperty(key) && Array.isArray(response[key])) {
      errorMessages += response[key].join(", ") + "; ";
    }
  }

  // Remove the trailing semicolon and space
  if (errorMessages.endsWith(". ")) {
    errorMessages = errorMessages.slice(0, -2);
  }

  return errorMessages;
};
