import React from 'react';
import './Special_Promotion.css';
import Footer from '../Footer/Footer';

const Service = () => {
    // function calculatePrice(saleTime) {
    //     const promotionStartTime = saleTime;
    //     const dayOfWeek = promotionStartTime.getDay();
    //     const hours = promotionStartTime.getHours();
      
    //     // Check if the sale falls within the blocked promotion period (Friday midnight to Monday midnight)
    //     if (dayOfWeek >= 5 && dayOfWeek <= 1) {
    //       return "Promotion blocked during the weekend.";
    //     }
      
    //     // Check the time periods for different prices
    //     if (dayOfWeek === 1 || (dayOfWeek === 2 && hours < 24)) {
    //       // Monday midnight to Wednesday midnight ($80)
    //       return 80;
    //     } else if (dayOfWeek === 3 || (dayOfWeek === 4 && hours < 24)) {
    //       // Wednesday midnight to Friday midnight ($100)
    //       return 100;
    //     } else {
    //       // Normal price after Friday midnight ($150)
    //       return 150;
    //     }
    //   }

    return (
        <div className="Service-page">
            <div className="service sp-container">
              <div className="sp-box rainbox-border">
                {/* <h1 className="text-center">Coming Soon</h1> */}
                <h4>ENTRANCE TICKET PROMOTION</h4>
                <div>Sunday midnight- Thursday midnight ~ $80</div>
                <div>Thursday midnight - Sunday midnight ~ $150</div>
              </div>
            </div>
            <Footer />
        </div>
    )
}

export default Service;