import React, { useState, useEffect } from 'react';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import './Menu.css';
import Footer from '../Footer/Footer';

const Menu = ({menus, menuCategories, isPage}) => {

    const [tag, setTag] = useState('SMALL BITES')
    const [filteredImages, setFilteredImages] = useState()
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setFilteredImages(menus.filter(menu => menu.category.name === tag))
    }, [tag])

    return (
        <div className="Menu-page">
                <div className="Menu1">
                    <div className="menu-top">
                        <h2 className="menu-top-title">DELICIOUS MENU<div className="mobile-title-line"></div></h2>

                        <div className="title-line"></div>
                        <p className="menu-top-description">The Finest Spanish Seafront Bar In Tsim Sha Tsui, We Provide Different Dining Food And Cocktails.</p>
                    </div>
                    <div className="menu-middle">
                        {
                            menuCategories.map((item,index) =>
                                <h6 key={item.id} className={
                                    "menu-middle-button "+`${tag === item.name ? 'active' : '' }`} onClick={() => setTag(item.name)}>{item.name}</h6>
                            )
                        }
                    
                    </div>
                   
                    <div className="menu-bottom1">
                        <div className="menu-bottom-col">
                            {filteredImages != null ? filteredImages.map((menu,index) => (
                                <div key={menu.id} className="menu-bottom-row col-12 col-sm-6" onClick={() => {
                                    setPhotoIndex(index)
                                    setIsOpen( true )
                                  }}>
                                    <img className="w-100" src={`${process.env.REACT_APP_IMAGE_URL}${menu.image}`} alt="menuPhoto" />
                                    {/* <div>
                                        <h4>{menu.menu_title}</h4>
                                        <p className="menu-bottom-row-description">(With Or Without Ketchep)</p>
                                    </div>
                                    <p className="menu-bottom-row-cost">HKD ${menu.price}</p> */}
                                </div>
                            )) : null}
                        </div>
                    </div>

                    {isOpen && (
                        <Lightbox
                            mainSrc={`${process.env.REACT_APP_IMAGE_URL}${filteredImages[photoIndex]['image']}`}
                            onImageLoad={() => {
                                window.dispatchEvent(new Event('resize'));
                            }}
                            nextSrc={`${process.env.REACT_APP_IMAGE_URL}${filteredImages[(photoIndex + 1) % filteredImages.length]['image']}`}
                            prevSrc={`${process.env.REACT_APP_IMAGE_URL}${filteredImages[(photoIndex + filteredImages.length - 1) % filteredImages.length]['image']}`}
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex(
                                    (photoIndex + filteredImages.length - 1) % filteredImages.length,
                                )
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex(
                                    (photoIndex + 1) % filteredImages.length,
                                )
                            }
                            imageCaption={<div style={{ padding: '16px', margin:'auto' }}>{filteredImages[photoIndex]['menu_content']}</div>}
                        />
                        )}
                </div>
            { isPage ? <Footer /> :null}
        </div>
    )
}

export default Menu;