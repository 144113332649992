import React from "react";
import Title from "../common/Title/Title";
import { useParams } from "react-router-dom";
import Footer from "../Footer/Footer";

const PaymentStatus = () => {
  const params = useParams();
  return (
    <section className="checkout__sec">
      <div style={{ height: "65vh" }}>
        <Title title={`Payment  ${params?.status}`} />

        {params?.status === "success" && (
          <div style={{ textAlign: "center", color: "white" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="42"
              viewBox="0 0 41 42"
              fill="none"
            >
              <path
                d="M11.3333 20.9401L19.3333 27.6068L30 14.2734M20.6667 39.6068C18.2153 39.6068 15.788 39.1239 13.5232 38.1859C11.2585 37.2478 9.2007 35.8728 7.46734 34.1394C5.73398 32.4061 4.359 30.3483 3.42092 28.0835C2.48283 25.8188 2 23.3914 2 20.9401C2 18.4888 2.48283 16.0614 3.42092 13.7967C4.359 11.5319 5.73398 9.47414 7.46734 7.74078C9.2007 6.00742 11.2585 4.63244 13.5232 3.69435C15.788 2.75627 18.2153 2.27344 20.6667 2.27344C25.6174 2.27344 30.3653 4.2401 33.866 7.74078C37.3667 11.2415 39.3333 15.9894 39.3333 20.9401C39.3333 25.8908 37.3667 30.6388 33.866 34.1394C30.3653 37.6401 25.6174 39.6068 20.6667 39.6068Z"
                stroke="white"
                stroke-width="3"
              />
            </svg>
            <p style={{ marginTop: "20px" }}>
              Your order has been placed. We’ll send you an email with your
              order details.
            </p>
          </div>
        )}
      </div>
      <Footer />
    </section>
  );
};

export default PaymentStatus;
