import React, { useState, useEffect } from 'react';
import './Home.css';
import Footer from '../Footer/Footer';
import Menu from '../Menu/Menu.js';
import whatsapp from '../../util/whatsappIcon.png';
import fbmessager from '../../util/fbmessagerIcon.png';
import aboutPhoto1 from '../../util/aboutPhoto1.png';
import aboutPhoto2 from '../../util/aboutPhoto2.png';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const Home = ({ banners, menus, menuCategories, store, about_text }) => {
    const [tag, setTag] = useState('SMALL BITES')
    const [filteredImages, setFilteredImages] = useState()

    useEffect(() => {
        setFilteredImages(menus.filter(menu => menu.category.name === tag))
    }, [tag])



    return (
        <div className="Home">
            <Carousel infiniteLoop={true} showArrows={false} autoPlay={true} showThumbs={false} emulateTouch={true}>
                {banners != null ?
                    banners.map(banner => (<div key={banner.id} className="home1" style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${banner.banner_image_path})` }}>
                        <div className="layer">
                            <div className="homepage">
                                <div className="homepage-top">
                                    <p className="homepage-top-text">BAR & PUBS</p>
                                    <p className="homepage-top-text">DANCE & NIGHT CLUB</p>
                                </div>
                                <div className="homepage-middle">
                                    <div className="homepage-middle-left">
                                        <p className="homepage-middle-text" id="homepage-middle-description">ONE OF LAN KWAI FONG'S HOTTEST,<br /> HIPPEST LIVE VENUES.</p>
                                        <div className="homepage-middle-text" id="homepage-middle-title">
                                            {store != null ? 
                                                <img
                                                    className="slider-logo"
                                                    src={`${process.env.REACT_APP_IMAGE_URL}${store.icon_image_path}`}
                                                    alt=""
                                                /> 
                                                : null
                                            }
                                        </div>
                                        {/* banner.banner_main_title */}
                                    </div>
                                </div>
                                <div className="homepage-mobile-right">
                                    <p className="homepage-mobile-text">BAR & PUBS</p>
                                    <p className="homepage-mobile-text">DANCE & NIGHT CLUB</p>
                                </div>
                                <div className="homepage-bottom">
                                    <div className="homepage-bottom-text">
                                        <p className="homepage-bottom-text-title">LADIES NIGHT</p>
                                        <p>In Every Wednesday And Thursday, Ladies Get Free Vodka Miers From 10pm Onwards</p>
                                    </div>
                                    <div className="homepage-bottom-text">
                                        <p className="homepage-bottom-text-title">HAPPY PARTY</p>
                                        <p>The Longest Happy Hour In LKF With A Wide Variety Of Cocktails & Mixes From Noon Right Through To 9pm</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>))
                    : null}
            </Carousel>
            <div className="About">
                <div className="about-top">
                    <h3 className="about-top-title">ABOUT US</h3>

                    <p className="about-top-description"
                        dangerouslySetInnerHTML={{ __html: `${about_text}` }}
                    />
                </div>
                <div className="about-bottom">
                    <div className="image">
                        <img className="about-photo1" src={aboutPhoto1} alt="" />
                        <div className="image__overlay image__overlay--primary">
                            <div className="image__title">PARTY</div>
                        </div>
                    </div>
                    <div className="image">
                        <img className="about-photo2" src={aboutPhoto2} alt="" />
                        <div className="image__overlay image__overlay--primary">
                            <div className="image__title">DRINKS</div>
                        </div>
                    </div>
                </div>
            </div>
            {menus ? (<Menu menus={menus} menuCategories={menuCategories} />) : null}

            {/* <div className="Menu">
                <div className="menu-top">
                    <h1 className="menu-top-title">DELICIOUS MENU</h1>
                    <div className="title-line"></div>
                    <p className="menu-top-description">The Finest Spanish Seafront Bar In Tsim Sha Tsui, We Provide Different Dining Food And Cocktails.</p>
                </div>
                <div className="menu-middle">
                    <p className="menu-middle-button" onClick={() => setTag('SMALL BITES')}>SMALL BITES</p>
                    <p className="menu-middle-button" onClick={() => setTag('BIG BITES')}>BIG BITES</p>
                    <p className="menu-middle-button" onClick={() => setTag('SWEET BITES')}>SWEET BITES</p>
                    <p className="menu-middle-button" onClick={() => setTag('COCKTAILS')}>COCKTAILS</p>
                    <p className="menu-middle-button" onClick={() => setTag('WINE')}>WINE</p>
                    <p className="menu-middle-button1" onClick={() => setTag('BEER')}>BEER</p>
                </div>
                <div className="menu-bottom">
                    <div className="menu-bottom-col">
                        {filteredImages != null ? filteredImages.map(menu => (
                            <div key={menu.id} className="menu-bottom-row">
                                <div>
                                    <h4>{menu.menu_title}</h4>
                                    <p className="menu-bottom-row-description">(With Or Without Ketchep)</p>
                                </div>
                                <p className="menu-bottom-row-cost">HKD ${menu.price}</p>
                            </div>
                        )) : null}
                    </div>
                </div>
            </div> */}
            <div className="homepage-mobile-bottom">
                <div className="homepage-mobile-bottom-text">
                    <h5 className="homepage-mobile-bottom-title">LADIES NIGHT</h5>
                    <h6 className="homepage-mobile-bottom-description">In Every Wednesday And Thursday, Ladies Get Free Vodka Miers From 10pm Onwards</h6>
                </div>
                <div>
                    <h5 className="homepage-mobile-bottom-title">HAPPY PARTY</h5>
                    <h6 className="homepage-mobile-bottom-description">The Longest Happy Hour in LKF With A Wide Variety Of Cocktails & Mixes From Noon Right Through To 9pm</h6>
                </div>
            </div>
            <Footer />
        </div >

    )
}

export default Home;