import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // Use local storage
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import addToCart from "./addToCartSlice/index";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import bookingSlice from "./bookingSlice";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  // Specify the slices to persist
};
const persistedCartReducer = persistReducer(
  persistConfig,
  addToCart // Only addToCart reducer will be persisted
);
const rootReducer = {
  cart: persistedCartReducer,
  booking: bookingSlice, // This reducer will not be persisted
};
// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;
