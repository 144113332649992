import React from "react";
import "./Title.css";

const Title = ({ title }) => {
  return (
    <div className="title__sec">
      <h1 className="title">{title}</h1>
      <div className="title__bottom-line" />
    </div>
  );
};

export default Title;
