import React, { useState, useEffect } from "react";
import "./Footer.css";
import instagramIcon from "../../util/instagramIcon.png";
import facebookIcon from "../../util/facebookIcon.png";
import { Link } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const [store, setStore] = useState(null);
  const [venueProps, setVenueProps] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVICE_URL}profile?store_id=6`
      );
      setVenueProps(response.data.venues);
      setStore(response.data.store);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="Footer">
      <div className="footer-top">
        <Link to="/">
          {store && (
            <img
              id="graffiti2"
              src={`${process.env.REACT_APP_IMAGE_URL}${store.icon_image_path}`}
              alt=""
            />
          )}
        </Link>
        <div className="footer-top-right">
          <Link to="/about" className="footer-top-right-button">
            ABOUT US
          </Link>
          <Link to="/booking" className="footer-top-right-button">
            BOOKING
          </Link>
          <Link to="/package" className="footer-top-right-button">
            PACKAGE
          </Link>
          <Link to="/menu" className="footer-top-right-button">
            MENU
          </Link>
          <Link to="/special_promotion" className="footer-top-right-button">
            SPECIAL PROMOTION
          </Link>
          <Link to="/gallery" className="footer-top-right-button">
            GALLERY
          </Link>
          <Link to="/contact" className="footer-top-right-button">
            CONTACT
          </Link>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-left">
          {store && (
            <div dangerouslySetInnerHTML={{ __html: store.footer_text }} />
          )}
          <p className="footer-copyright-bottom">
            © Zinc Group all rights reserved
          </p>
        </div>
        <div className="footer-right">
          <div>
            <h3>Our Venues</h3>
            {venueProps &&
              venueProps.map((venue) => (
                <div className="col-6" key={venue.id}>
                  <a className="linkdeco" href={venue.website}>
                    {venue.name}
                  </a>
                </div>
              ))}
          </div>
          <div className="footer-right3">
            <h3>Stay In Touch</h3>
            <p>Join Us</p>
            <p>Subscribe</p>
            {store && (
              <div className="footer-right3-logo">
                <a href={store.ig_link}>
                  <img
                    className="footer-logo"
                    src={instagramIcon}
                    alt="Instagram"
                  />
                </a>
                <a href={store.facebook_link}>
                  <img
                    className="footer-logo"
                    src={facebookIcon}
                    alt="Facebook"
                  />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="footer-mobile">
        <div className="footer-mobile-top">
          <h3 className="footer-mobile-title1">Stay In Touch</h3>
          <p className="footer-mobile-description">Join Us</p>
          <p className="footer-mobile-description">Subscribe</p>
          {store && (
            <div>
              <a className="mobile-social-link1" href={store.ig_link}>
                <img
                  className="footer-mobile-icon"
                  src={instagramIcon}
                  alt="Instagram"
                />
              </a>
              <a className="mobile-social-link1" href={store.facebook_link}>
                <img
                  className="footer-mobile-icon"
                  src={facebookIcon}
                  alt="Facebook"
                />
              </a>
            </div>
          )}
        </div>
        <div className="footer-mobile-middle">
          <h3 className="footer-mobile-title1">Our Venues</h3>
          {venueProps &&
            venueProps.map((venue) => (
              <div className="col-6" key={venue.id}>
                <a className="linkdeco" href={venue.website}>
                  {venue.name}
                </a>
              </div>
            ))}
        </div>
        <div className="footer-mobile-bottom">
          {store && (
            <div dangerouslySetInnerHTML={{ __html: store.footer_text }} />
          )}
          <p>© Zinc Group all rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
