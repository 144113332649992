import toast from "react-hot-toast";
import { Link } from "react-router-dom";

export const showError = () => {
  toast.error((t) => (
    <div className="toast-msg__cover">
      <div className="toast-msg__hero-sec">
        <h2 style={{ textAlign: "center" }} className="  error_msg ">
          Already added in your cart
        </h2>
      </div>
      <div className="toast-msg__btn-cover">
        <Link to="/cart" className="toast_btn">
          View cart
        </Link>
        <Link to="/checkout" className="toast_btn">
          Checkout
        </Link>
      </div>
    </div>
  ));
};
