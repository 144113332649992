import { useState, useEffect } from "react";
import "./App.css";
import Header from "../Header/Header";
import Home from "../Home/Home";
import About from "../About/About";
import Service from "../Service/Service";
import Menu from "../Menu/Menu";
import Special_Promotion from "../Special_Promotion/Special_Promotion";
import whatsappIcon from "../../util/whatsappIcon.png";
import fbmessagerIcon from "../../util/fbmessagerIcon.png";
import Gallery from "../Gallery/Gallery";
import Contact from "../Contact/Contact";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import axios from "axios";
import { MyCart } from "../../util/icons";
import Package from "../Package/Package";
import Booking from "../Booking/Package";
import BookingDetails from "../Booking/packageId";
import Cart from "../Booking/Cart/Cart";
import { useSelector } from "react-redux";
import Checkout from "../Checkout/Checkout";
import PaymentStatus from "../Checkout/PaymentStatus";

function App() {
  const [store, setStore] = useState();
  const [banners, setBanners] = useState();
  const [menus, setMenus] = useState();
  const [menuCategories, setMenuCategories] = useState();

  const [galleries, setGalleries] = useState();
  const [about, setAbout] = useState();
  const data = useSelector((state) => state.cart.cartList);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVICE_URL}profile?store_id=6`)
      .then((response) => {
        setAbout(response.data.store.about_text);
        setBanners(response.data.banners);
        setMenuCategories(response.data.menuCategories);
        setMenus(response.data.menus);
        setStore(response.data.store);
        setGalleries(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios
      .post(`${process.env.REACT_APP_SERVICE_URL_BOOKING}/addview`)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="App">
      {store != null ? <Header store={store} /> : null}
      <Routes>
        {menus != null ? (
          <Route
            path="/"
            element={
              <Home
                banners={banners}
                menuCategories={menuCategories}
                menus={menus}
                store={store}
                about_text={about}
              />
            }
          />
        ) : null}
        {store != null ? (
          <Route path="/about" element={<About about_text={about} />} />
        ) : null}

        <Route path="/package" element={<Package />} />

        <Route path="/service" element={<Service />} />

        {menus != null ? (
          <Route
            path="/menu"
            element={
              <Menu
                menus={menus}
                menuCategories={menuCategories}
                isPage={true}
              />
            }
          />
        ) : null}

        <Route path="/special_promotion" element={<Special_Promotion />} />

        {galleries != null ? (
          <Route path="/gallery" element={<Gallery galleries={galleries} />} />
        ) : null}

        {store != null ? (
          <Route path="/contact" element={<Contact store={store} />} />
        ) : null}

        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout/:status" element={<PaymentStatus />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/booking/:packageId" element={<BookingDetails />} />
      </Routes>

      {store != null ? (
        <div className="home-icons">
          {/* <Link to="/cart" className="cart_style">
            <span className="cart_length">{data?.length}</span>
            <div className="social-btn">
              <MyCart />
            </div>
          </Link>

          <h6 className="social-text">My cart</h6> */}
          {/* <a href={store.whatsapp} className="social-link">
            <img className="social-btn" src={whatsappIcon} alt="" />
          </a>
          <a href={store.whatsapp} style={{ textDecoration: "none" }}>
            <h6 className="social-text">whatsapp</h6>
          </a>
          <a href={store.facebook_messenger} className="social-link">
            <img className="social-btn" src={fbmessagerIcon} alt="" />
          </a>
          <a href={store.facebook_messenger} style={{ textDecoration: "none" }}>
            <p className="social-text">facebook</p>
          </a> */}
        </div>
      ) : null}
    </div>
  );
}

export default App;
