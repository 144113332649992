import React, { useState } from "react";
import "./Cart.css";

import { useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../../store/addToCartSlice";

const Card = ({ item }) => {
  const [tableBookinQty, setTableBookinQty] = useState(item?.qty);
  const dispatch = useDispatch();
  const addQuantity = () => {
    setTableBookinQty((prev) => prev + 1);
    dispatch(addToCart({ id: item?.id, qty: tableBookinQty + 1 }));
  };
  const decreaseQuantity = () => {
    if (tableBookinQty === 1) return false;
    setTableBookinQty((prev) => prev - 1);
    dispatch(addToCart({ id: item?.id, qty: tableBookinQty - 1 }));
  };

  const handleRemoveCart = () => {
    dispatch(removeFromCart(item));
  };

  return (
    <main className="cart_card">
      <img
        alt="title"
        src={item?.img_path}
        style={{
          width: "300px",
          height: "auto",
        }}
      />
      <div className="block_1">
        <h3>{item?.type}</h3>
        <div className="title__bottom-line" />
        {/* <div className="entry" > {item?.desc}</div> */}
         <div className="rendeHtml entry" dangerouslySetInnerHTML={{ __html: item?.desc }}></div>
      </div>
      <div className="block_2">
        <h3>HK ${item?.price}</h3>
        <div className="flex">
          <div className="btn ">
            <span className="btn-quantity-text">QTY</span>
            <button className="btn-minus" onClick={decreaseQuantity}>
              -
            </button>
            <span className="btn-quantity-value">{tableBookinQty}</span>
            <button className="btn-plus" onClick={addQuantity}>
              +
            </button>
          </div>
          <div className="cross" onClick={handleRemoveCart}>
            X
          </div>
        </div>
      </div>
    </main>
  );
};

export default Card;
