import React, { useEffect } from "react";
import "./Package.css";

import PackageCard from "./PackageCard";

import { fetchBooking } from "../../store/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";

const Package = () => {
  const packageData = useSelector((state) => state.booking.bookingList);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBooking());
  }, [dispatch]);
  return (
    <main className="package-sec">
      {/* <Title title={"Package"} /> */}
      <div className="package-cards">
        {packageData?.length > 0 ? (
          packageData?.map((item) =>
            item.is_active && !item.is_package ? (
              <PackageCard key={item.id} item={item} />
            ) : (
              ""
            )
          )
        ) : (
          <h1 style={{ minHeight: "35vh" }}>Not available</h1>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default Package;
