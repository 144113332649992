import { createAction, createSlice } from "@reduxjs/toolkit";

export const addToCart = createAction("cart/addToCart");
export const removeFromCart = createAction("cart/removeFromCart");

const addToCartSlice = createSlice({
  name: "cart",
  initialState: {
    cartList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // ...

    builder
      .addCase(addToCart, (state, action) => {
        const itemToAdd = action.payload;
        const existingItemIndex = state.cartList.findIndex(
          (item) => item.id === itemToAdd.id
        );

        if (existingItemIndex !== -1) {
          // If item with the same id already exists, update the quantity
          state.cartList[existingItemIndex].qty = itemToAdd.qty;
          state.cartList[existingItemIndex].price = itemToAdd.price;
        } else {
          // If item doesn't exist, push it to the cartList
          state.cartList.push(itemToAdd);
        }
      })
      .addCase(removeFromCart, (state, action) => {
        const itemToRemove = action.payload;
        state.cartList = state.cartList.filter(
          (item) => item.id !== itemToRemove.id
        );
      });
  },
});
export default addToCartSlice.reducer;
