import React, { useEffect, useState } from "react";
import "./Cart.css";

// import Title from "../common/Title/Title";
import Card from "./Card";
import { useDispatch, useSelector } from "react-redux";
import { fetchBooking } from "../../../store/bookingSlice";
import { addToCart } from "../../../store/addToCartSlice";
import { Link } from "react-router-dom";
import Title from "../../common/Title/Title";
import Footer from "../../Footer/Footer";

// added to cart toast message

const Cart = () => {
  const [tableBookinQty, setTableBookinQty] = useState(1);
  const data = useSelector((state) => state.cart.cartList);
  const addQuantity = () => {
    setTableBookinQty((prev) => prev + 1);
  };
  const decreaseQuantity = () => {
    if (tableBookinQty === 1) return false;
    setTableBookinQty((prev) => prev - 1);
  };

  const packageData = useSelector((state) => state.booking.bookingList);
  //  /booking page layout
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBooking());
  }, [dispatch]);

  useEffect(() => {
    if (packageData && packageData.length > 0) {
      data && data.map((ele) => {
        const item = packageData.find((item) => ele.id === item.id);
        if (item) {
          dispatch(addToCart({ ...ele, price: item.price }));
        }
      });
    }
  }, [packageData, data, dispatch]);

  return (
    <main className="cart-sec">
      <Title title={"Cart"} />
      <div className="cards">
        {data?.length === 0 && (
          <h2 style={{ height: "30vh" }} className="">
            {" "}
            Cart is Empty
          </h2>
        )}
        {data && data?.map((item, index) => <Card key={index} item={item} />)}
        <Link className="checkout" to="/checkout">
          {data?.length != 0 && <button className="">Checkout</button>}
        </Link>
      </div>
      <Footer />
    </main>
  );
};

export default Cart;
