import React from "react";
import { Link } from "react-router-dom";

import "./PackageCard.css";

const PackageCard = ({ item }) => {
  return (
    <div className="booking-card__box">
      {item?.img_path && (
        <img
          src={item?.img_path}
          // src={Photo}
          alt={item?.description}
          className="card-img"
        />
      )}
      <h3 className="card-title">{item?.type}</h3>
      <div className="card-content rendeHtml" dangerouslySetInnerHTML={{ __html: item?.description }}></div>
      <div className="card-link__cover">
        <Link className="card-link mt-1" to={`/booking/${item.id}`}>
          More
        </Link>
      </div>
    </div>
  );
};

export default PackageCard;
