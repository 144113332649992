import React from 'react';
import Footer from '../Footer/Footer';
import './About.css';

const About = ({about_text}) => {
    return (
        <div className="About-page">
            <div className="about-page-main">
                <div className="about-top">
                    <h2 className="about-top-title">ABOUT US<div className="mobile-title-line"></div></h2>
                   
                    <p className="about-top-description"
                        dangerouslySetInnerHTML={{ __html: `${about_text}` }}
                    />
                </div>
            </div>
            <Footer />

        </div>
    )
}

export default About;