import React, { useEffect, useState } from "react";
import "./PackageDetails.css";

// import PackageImg1 from "../../../util/booking/package-1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../store/addToCartSlice";
import { showToast } from "../../common/showToast";
import { fetchPackageDetails } from "../../../store/bookingSlice";
import { useParams } from "react-router-dom";
import { showError } from "../../common/showError";
import Footer from "../../Footer/Footer";
import { checkPromote } from "../../../api/index.js";

// added to cart toast message

const PackageDetails = () => {
  const [tableBookinQty, setTableBookinQty] = useState(1);
  const bookingList = useSelector((state) => state.booking.packageDetails);
  const cart = useSelector((state) => state.cart.cartList);

  const params = useParams();
  const dispatch = useDispatch();
  const handleAddToCart = () => {
    dispatch(
      addToCart({
        id: bookingList?.id,
        qty: tableBookinQty,
        img_path: bookingList?.img_path,
        price: bookingList?.price,
        desc: bookingList?.description,
        type: bookingList?.type,
      })
    );
    const found = cart?.find((item) => item.id === bookingList?.id);
    if (found) {
      showError();
    } else {
      showToast(tableBookinQty, bookingList);
    }
  };

  const addQuantity = () => {
    setTableBookinQty((prev) => prev + 1);
  };
  const decreaseQuantity = () => {
    if (tableBookinQty === 1) return false;
    setTableBookinQty((prev) => prev - 1);
  };
  useEffect(() => {
    dispatch(fetchPackageDetails(params.packageId));
  }, [dispatch]);
  return (
    <main className="package-details__sec">
      <div className="package-details">
        <div className="package-img__cover">
          {bookingList?.img_path ? (
            <img
              src={bookingList?.img_path}
              alt="package details"
              className="package-img"
            />
          ) : (
            <img
              src={bookingList?.img_path}
              alt="package details"
              className="package-img"
            />
          )}
        </div>
        <div className="package-details__cover">
          <div>
            <h2 className="package-details__title">{bookingList?.type}</h2>
            <div className="package-details__title-line" />
          </div>
          <div className="package-details__content rendeHtml" dangerouslySetInnerHTML={{ __html: bookingList?.description }} ></div>
          <h2 className="package-details__price">HK ${bookingList?.price}
          </h2>
          <div className="pacakge-details__btn-cover">
            <div className="button btn-quantity">
              <span className="btn-quantity-text">QTY</span>
              <button className="btn-minus" onClick={() => decreaseQuantity()}>
                -
              </button>
              <span className="btn-quantity-value">{tableBookinQty}</span>
              <button className="btn-plus" onClick={() => addQuantity()}>
                +
              </button>
            </div>
            <button onClick={() => handleAddToCart()} className="button">
              Add to Cart
            </button>
          </div>
          <p className="package-details__content">
            *Patrons must be 18+ and not prohibited from the Entertainment at
            our venue. Embassy practices responsible service of alcohol. Entry
            and VIP Booths are subject to availability and bookings are
            recommended.
          </p>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default PackageDetails;
