import React from 'react';
import './Service.css';
import Footer from '../Footer/Footer';
import aboutPhoto1 from '../../util/servicePhoto1.png';
import aboutPhoto2 from '../../util/servicePhoto2.png';

const Service = () => {
    return (
        <div className="Service-page">
            <div className="service">
                <h2 className="service-title">SERVICE</h2>
                <div className="mobile-title-line"></div>
                <div class="d-flex flex-wrap justify-content-center justify-content-lg-even">
                    <div className="service-image">
                        <img className="service-photo1" src={aboutPhoto1} alt="" />
                        <div className="image__overlay image__overlay--primary">
                            <div className="image__title">PARTY</div>
                        </div>
                    </div>
                    <div className="service-image">
                        <img className="service-photo2" src={aboutPhoto2} alt="" />
                        <div className="image__overlay image__overlay--primary">
                            <div className="image__title">DRINKS</div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Service;