import toast from "react-hot-toast";
// import { bookingImage } from "../../util";
import { Link } from "react-router-dom";

export const showToast = (Qty, bookingList) => {
  toast.success((t) => (
    <div className="toast-msg__cover">
      <div className="toast-msg__hero-sec">
        {
          bookingList && (
            <img
              alt="title"
              src={bookingList?.img_path}
              style={{
                width: "100px",
                height: "80px",
                marginBottom: "8px",
                objectFit: "cover",
              }}
            />
          )
          // : (
          //   <img
          //     alt="title"
          //     src={bookingImage.Entrance}
          //     style={{
          //       width: "100px",
          //       height: "80px",
          //       marginBottom: "8px",
          //       objectFit: "cover",
          //     }}
          //   />
          // )
        }

        <div className="toast-msg__text-cover">
          <h2 className="toast-msg__title">{bookingList?.type}</h2>
          <p className="toast-msg__content">
            success: You Have Added {Qty} x {bookingList?.type} to your cart!
          </p>
        </div>
      </div>
      <div className="toast-msg__btn-cover">
        <Link to="/cart" className="toast_btn">
          View cart
        </Link>
        <Link to="/checkout" className="toast_btn">
          Checkout
        </Link>
      </div>
    </div>
  ));
};
