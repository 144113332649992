import React from 'react';
import Footer from '../Footer/Footer';
import facebookIcon from '../../util/facebookIcon.png';
import graffitiLogo from '../../util/graffiti.png';
import whatsappIcon from "../../util/whatsappIcon.png";
import './Contact.css';

const Contact = ({ store }) => {
    return (
        <div className="Contact-page">
            <div className="contact">
                <h2 className="contact-mobile-title">CONTACT<div className="mobile-title-line"></div></h2>

                <p className="contact-mobile-text">RECORD YOUR MEMORIES IN GRAFFITI!!</p>
                <iframe src={store.google_map} title="google_map" style={{ border: 0, filter: 'invert(80%)',width:'100%', maxWidth: '600px', height: '450px' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <div className="contact-right">
                    <div className="contact-right-top" id="contact-desktop">
                        <h2 className="contact-right-top-title">OUR OPENING HOURS</h2>
                        <h5 className="contact-right-top-text">{store.working_hour}</h5>
                    </div>
                    <div className="contact-right-top">
                        <img src={graffitiLogo} className="contact-logo" alt="" />
                        <h2 className="contact-right-top-title">OUR LOCATION</h2>
                        <h6 className="contact-right-top-text">{store.address}</h6>
                    </div>
                    <div className="contact-right-top" id="contact-mobile">
                        <h2 className="contact-right-top-title">OUR OPENING HOURS</h2>
                        <h5 className="contact-right-top-text">{store.working_hour}</h5>
                    </div>
                    <div className="contact-right-top">
                        <h2 className="contact-right-top-title">OUR CONTACT</h2>
                        <h5 className="contact-right-top-text mb-3">TEL: <a target="_blank" href={'tel:' + store.phone} style={{color: '#BDBDBD'}} className='cursor-pointer text-no-underline'>{store.phone}</a> | FAX: 2526 4422</h5>

                        <a target="_blank" href={process.env.REACT_APP_WHATSAPP_URL + store.whatsapp} className="contact-right-bottom-text cursor-pointer text-no-underline">
                            <div>
                                <img alt="" style={{width:'35px'}} className="contact-fb" src={whatsappIcon} />
                            </div>
                            <p className="contact-right-top-text t-18">{store.whatsapp}</p>
                        </a>

                        <a target="_blank" href={store.facebook_messenger} className="contact-right-bottom-text cursor-pointer text-no-underline">
                            <div>
                                <img alt="" className="contact-fb" src={facebookIcon} />
                            </div>
                            <p className="contact-right-top-text">FOLLOW US ON FACEBOOK: GRAFFITI LKF</p>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Contact;